import { module } from 'modujs';
import modularLoad from 'modularload';
// import { gsap } from 'gsap';
import { $html, $body } from '../utils/dom';
import createMap from '../utils/mapbox-gl';

export default class Load extends module {

  static ENTER_DELAY = 800;
  static SIZE_DELAY = 600
  static TRANSITION_DELAY = 400



  constructor(m) {
    super(m);


    this.$map = document.querySelector('#mapbox-gl')
  }





  init() {
    // console.log($map)
    if (document.querySelector('[data-map-script]') && !document.querySelector('#mapbox-gl')) {
      createMap();
      this.call('initMap', null, 'Map');
    }

    // gsap.delayedCall(0.6, () => {
    //   this.setSizes();
    // });
    setTimeout(() => {
      this.setSizes();
    }, Load.Delay);

    this.load = new modularLoad({
      enterDelay: Load.ENTER_DELAY,
      transitions: {
        customTransition: {}
      }
    });

    this.load.on('loading', (transition, oldContainer) => {
      $html.classList.add('has-transition');
      $html.classList.remove('has-dom-ready');
      $html.classList.remove('has-dom-ready-callback');
      $html.classList.remove('has-menu-open');
      $html.classList.remove('has-modal-open');
      this.call('destroy', oldContainer, 'Map');


    });

    this.load.on('loaded', (transition, oldContainer, newContainer) => {
      this.call('destroy', oldContainer, 'app');
      this.call('destroy', oldContainer, 'Map');
      this.call('update', newContainer, 'app');

      window.scrollTo(0, 0);
      // $html.classList.remove('has-menu-open');

      // console.log(newContainer.querySelector('[data-map-script]'), newContainer.querySelector('#mapbox-gl'))
      if (newContainer.querySelector('[data-map-script]')) {
        this.call('initMap', newContainer, 'Map');
        if (document.querySelector('#mapbox-gl')) return
        createMap();

      }

      // gsap.delayedCall(0.6, () => {
      //   this.setSizes();
      // });
      setTimeout(() => {
        this.setSizes();
      }, Load.Delay);

      // gsap.delayedCall(0.4, () => {
      //   $html.classList.remove('has-transition');
      // })

      setTimeout(() => {
        $html.classList.remove('has-transition');
      }, Load.TRANSITION_DELAY);
    });
  }

  setSizes() {

    if (window.innerHeight - document.documentElement.clientHeight > 0) {
      $body.classList.add('has-scrollbar-y');
      document.documentElement.style.setProperty('--scrollbar', `${window.innerHeight - document.documentElement.clientHeight}px`);
    } else {
      $body.classList.remove('has-scrollbar-y');
    }

    // screen height
    document.documentElement.style.setProperty('--app-availheight', `${window.screen.availHeight}px`);
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty('--app-height-negative', `-${window.innerHeight}px`);

    window.addEventListener('resize', () => {

      if (!window.isMobile) {
        document.documentElement.style.setProperty('--app-availheight', `${window.screen.availHeight}px`);
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
        document.documentElement.style.setProperty('--app-height-negative', `-${window.innerHeight}px`);
      }
    });
  }
}
