
import { module } from 'modujs';
import { lazyLoadImage } from '../utils/image'
import LocomotiveScroll from 'locomotive-scroll';
import { createrScrollbar } from "../utils/scrollbar";
import { $html } from '../utils/dom';
import IS_TOUCH_DEVICE from '../app';

export default class Scroll extends module {

  static SCROLL_DURATION = 0.8;
  static SCROLLED_HEIGHT = 100;

  constructor(m) {
    super(m);


    if (history.scrollRestoration) {
      history.scrollRestoration = "manual";

      window.scrollTo(0, 0);
    }



    this.$el = this.el



    this.$wrapper = typeof this.getData('wrapper') == 'string' ? document.querySelector(this.getData('wrapper')) : window;


    createrScrollbar(this.el.parentNode)


    this.onScrollBind = this.onScroll.bind(this);
    this.onDragStartBind = this.onDragStart.bind(this);
    this.onDragMoveBind = this.onDragMove.bind(this);
    this.onDragEndBind = this.onDragEnd.bind(this);


    this.onResizeBind = this.onResize.bind(this)

    this.$scrollbarThumb = document.getElementById("thumb");




    this.dragging = false;
    this.startY = 0;
    this.startScrollY = 0;
    this.$progressTime = 0;

    this.height = 0

    // Fix scroll to link for webflow
    document.querySelectorAll("a").forEach((a) => {
      if (a.getAttribute("href") == null) return
      const regex = /^#\w+$/
      if (a.getAttribute("href").match(regex))
        a.addEventListener("click", (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.locomotiveScroll.scrollTo(a.getAttribute("href"));
        })
    });
  }

  init(scroll) {
    this.bindEvents()
    // this.scrollInit();
    this.locomotiveScroll = new LocomotiveScroll({
      triggerRootMargin: "-1px -5% -1px -5%",
      modularInstance: this,
      scrollCallback: this.onScrollBind,

      lenisOptions: {
        // wrapper: this.el.parentNode,
        // content: this.el,
        // smoothWheel: true,
        // smoothTouch: true,
        duration: Scroll.SCROLL_DURATION,
        // lerp: 0.1,
        // wheelMultiplier: 0.7,
        // touchMultiplier: 1.7,
        // touchInertiaMultiplier: 12
      }
    });



  }

  ///////////////
  // Callbacks
  ///////////////
  onResize() {
    this.locomotiveScroll?.resize();
  }



  onScroll({ scroll, limit, velocity, direction, progress }) {
    window.locomotiveScrollData = { scroll, limit, velocity, direction, progress };
    this.$progressTime = { progress };
    // direction = direction < 0 ? -1 : 1;

    // if(direction){
    //   if(di)
    // }

    if (direction) {
      if (direction < 0) {
        $html.setAttribute("data-scroll-direction", direction);
      } else {
        $html.setAttribute("data-scroll-direction", direction);
      }
    }



    // Update thumb position during regular scrolling
    this.$scrollbarThumb.style.transform = `translate3d(0,${progress * (window.innerHeight - this.height)}px,0)`;

    if (scroll > Scroll.SCROLLED_HEIGHT) {
      $html.classList.add('has-scrolled');
      this.call('close', null, 'EasterEgg');
    }
    if (scroll < Scroll.SCROLLED_HEIGHT) {
      $html.classList.remove('has-scrolled');
    }


    const footer = document.querySelector(".c-footer")

    if (!footer) return

    const footerHeight = footer.scrollHeight

    if (!IS_TOUCH_DEVICE) {
      if (scroll >= limit - footer) {
        $html.classList.add('is-bottom');
      } else {
        $html.classList.remove('is-bottom');
      }
    }
  }


  onDragStart(event) {
    document.documentElement.classList.add('has-scroll-dragging')
    this.dragging = true;
    this.startY = event.clientY;
    this.startScrollY = this.locomotiveScroll.lenisInstance.targetScroll;
  }

  onDragMove(event) {

    if (this.dragging) {
      const deltaY = event.clientY - this.startY;
      const scrollDelta = deltaY * (this.locomotiveScroll.lenisInstance.limit / window.innerHeight);
      this.locomotiveScroll.scrollTo(this.startScrollY + scrollDelta);
    }
  }

  onDragEnd() {
    document.documentElement.classList.remove('has-scroll-dragging')
    this.dragging = false;
  }

  ///////////////
  // Events
  ///////////////
  bindEvents() {
    window.addEventListener("resize", this.onResizeBind);
  }

  unbindEvents() {
    window.removeEventListener("resize", this.onResizeBind);
  }


  /**
   * Lazy load the related image.
   *
   * @see ../utils/image.js
   *
   * It is recommended to wrap your `<img>` into an element with the
   * CSS class name `.c-lazy`. The CSS class name modifier `.-lazy-loaded`
   * will be applied on both the image and the parent wrapper.
   *
   * ```html
   * <div class="c-lazy o-ratio u-4:3">
   *     <img data-scroll data-scroll-call="lazyLoad, Scroll, main" data-src="http://picsum.photos/640/480?v=1" alt="" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
   * </div>
   * ```
   *
   * @param {LocomotiveScroll} args - The Locomotive Scroll instance.
   */
  lazyLoad(args) {
    lazyLoadImage(args.target);
  }

  removeScrollElements($oldContainer) {
    this.locomotiveScroll?.removeScrollElements($oldContainer);
  }

  addScrollElements($newContainer) {
    this.locomotiveScroll?.addScrollElements($newContainer);
  }

  stop() {
    this.locomotiveScroll?.stop();
  }

  start() {
    this.locomotiveScroll?.start();
  }
  update(progress) {
    this.onResize();
  }

  /**
   * ScrollTo
   *
   * @param {Int, NodeElement or String} target - The scrollTo a target
   * @param {Object} options - The scrollTo options (offset, duration, easing, immediate)
   *
   * @see https://github.com/studio-freight/lenis#methods
   *
   */
  scrollTo(params) {
    const { target, options } = params;
    console.log(params)

    this.locomotiveScroll?.scrollTo(target, options);

  }

  destroy() {
    super.destroy();
    this.locomotiveScroll?.destroy();

    // Events
    this.unbindEvents();

    // Remove event listeners on destroy
    this.$scrollbarThumb.removeEventListener('mousedown', this.onDragStartBind);
    this.$scrollbarThumb.removeEventListener('touchstart', this.onDragStartBind);

    this.$scrollbarThumb.removeEventListener('mousemove', this.onDragMoveBind);
    this.$scrollbarThumb.removeEventListener('touchmove', this.onDragMoveBind);

    document.removeEventListener('mousemove', this.onDragMoveBind);
    document.removeEventListener('mouseup', this.onDragEndBind);
  }
}
