import { module } from "modujs";
// import { Map, Marker } from "mapbox-gl";
import { STYLE } from "../utils/mapstyle";
import { $body } from "../utils/dom";



export default class Maps extends module {

  static ACCESS_TOKEN = "pk.eyJ1IjoiYmFidXNoa2luIiwiYSI6ImNqeXpuMGJjNTAzM3ozYnVvZ2lmOG56ZmEifQ.-u7tqyy34VlJ9taudLtHhQ";
  static STEP = 0;
  static TIMEOUT = 800;
  constructor(m) {
    super(m);

    // Elements

    this.$el = this.el
    this.$map = this.$("map")[0];
    this.$list = this.$("list")[0];
    this.$items = this.$("item");
    // this.$script = this.getData('script');

    this.MAP_READY = false;

    // Data

    // Events
    this.events = {
      click: {
        "item": "clickItem"
      }
    };
  }

  initMap() {

    const interval = setInterval(() => {
      if (window.mapboxgl) {
        this.Map();
        clearInterval(interval);
      }
    }, 100);
  }


  Map() {



    this.map = new mapboxgl.Map({
      accessToken: Maps.ACCESS_TOKEN,
      container: this.$map,
      style: STYLE,
      center: [39.159645, 51.710884],
      zoom: 2.8,
      doubleClickZoom: false,
      minZoom: 2.8,
      maxZoom: 2.8,
      scrollZoom: false,
      keyboard: false,
      boxZoom: false,
      dragRotate: false,
    });


    this.map.on('load', () => {

      const geojson = {
        'type': 'FeatureCollection',
        'features': [
        ]
      }

      this.$items.forEach(element => {
        const attr = element.getAttribute('data-coord');
        if (attr !== "") {
          const row = '{"type": "Feature","geometry": {"type": "Point","coordinates":  ' + attr + '}}'

          geojson.features.push(JSON.parse(row));
        }
      });

      this.map.addSource('points', {
        'type': 'geojson',
        'data': geojson
      });


      for (const feature of geojson.features) {

        // code from step 5-1 will go here

        const el = document.createElement('div');
        el.className = 'c-map_marker';

        // make a marker for each feature and add to the map
        // new Marker().setLngLat(feature.geometry.coordinates).addTo(this.map);  // Replace this line with code from step 5-2
        this.marker = new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates).addTo(this.map);

        //code from step 6 will go here

      }
    });










  }

  active() {


    const coord = JSON.parse(this.$items[0].getAttribute('data-coord'));

    setTimeout(() => {
      this.setPoint({
        lngLat: {
          lat: coord[1],
          lng: coord[0]
        }
      })
      this.call("onResize", null, "Scroll");
      this.map.resize();
    }, Maps.TIMEOUT)

  }


  clickItem(e) {
    const target = e.curTarget;
    const attr = target.getAttribute('data-coord');

    this.$items.forEach(element => {
      element.classList.remove('is-active');
    })

    if (attr !== "") {
      const coord = JSON.parse(attr);
      this.setPoint({
        lngLat: {
          lat: coord[1],
          lng: coord[0]
        }
      })
      target.classList.add('is-active');
    }

  }



  setPoint(e) {
    const bc = {}
    const lat = e.lngLat.lat - Maps.STEP;
    const lng = e.lngLat.lng;

    bc.lng = lng;
    bc.lat = lat;

    this.map.flyTo({
      center: bc,
      speed: 0.5,
      zoom: 3
    })


    this.$items.forEach(element => {
      const attr = element.getAttribute('data-coord');
      if (attr !== "") {
        const coord = JSON.parse(attr);
        // console.log(coord[0], lng, coord[1], lat);
        if (coord[0] === lng && coord[1] - Maps.STEP === lat) {
          element.classList.add('is-active');
        } else {
          element.classList.remove('is-active');
        }
      }
    });
  }

  bindEvents() {

  }

  unbindEvents() {
  }

  destroy() {
    super.destroy();
    // this.map.remove();
  }
}