import { $body } from "./dom";

export function createrScrollbar(el) {
  const scrollbarParent = document.createElement("div");
  scrollbarParent.className = "c-scrollbar";

  const scrollbarThumb = document.createElement("div");
  scrollbarThumb.className = "c-scrollbar_thumb";

  scrollbarThumb.setAttribute("id", "thumb");

  scrollbarParent.appendChild(scrollbarThumb);
  el.appendChild(scrollbarParent);
}
