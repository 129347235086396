import { module } from "modujs";
import { $html } from "../utils/dom";

export default class Modal extends module {

  static CLASS = "has-modal-open";
  constructor(m) {
    super(m)

    this.events = {
      click: {
        "close": "close"
      }
    }
  }

  init() {
    // $html.classList.add(Modal.CLASS);

  }

  open() {
    $html.classList.add(Modal.CLASS);
    this.call('stop', null, 'Scroll');
  }

  close() {
    $html.classList.remove(Modal.CLASS);
    this.call('start', null, 'Scroll');
  }
}