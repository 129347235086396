import { module } from "modujs";
import Swiper from "swiper";
import { Navigation, Pagination, Parallax } from "swiper/modules";

export default class extends module {
  constructor(m) {
    super(m);

    // Elements

    this.$el = this.el;
    this.$slider = this.$('slider')[0];
    this.$prev = this.$('prev')[0];
    this.$next = this.$('next')[0];
    this.$pagination = this.$('pagination')[0];

  }

  init() {

    Swiper.use([Navigation, Pagination, Parallax]);


    this.options = {
      slidesPerView: "auto",
      parallax: true,

      // centeredSlides: true,
      navigation: {
        nextEl: this.$next,
        prevEl: this.$prev
      },
      pagination: {
        el: this.$pagination,
        type: "fraction",
      },
      // effect: "creative",
      // creativeEffect: {
      //   prev: {
      //     shadow: true,
      //     translate: ["-20%", 0, -1],
      //   },
      //   next: {
      //     translate: ["100%", 0, 0],
      //   },
      // },
    }

    this.swiper = new Swiper(this.$slider, {
      // loop: true,


      speed: 600,
      grabCursor: true,
      ...this.options
    });
  }

  destroy() {
    super.destroy();
    this.swiper.destroy();
  }
} 